<template>
  <TransitionRoot as="template" :show="props.isShow">
    <Dialog as="div" class="relative z-50" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-90 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0 overflow-y-auto"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-80 transform  text-sm rounded-lg bg-slate-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 "
            >
              <p class="text-xl mb-5">{{content?.title}}</p>

              <div class="mb-10">
                <div class="mx-1 mb-1 space-y-1">
                  <p>{{content?.text1}}</p>
                  <p>{{content?.text2}}</p>
                </div>

                <div class="mt-5">
                  <input 
                    class="w-full h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-300 border rounded-lg focus:shadow-outline" 
                    :class="errorPin ? 'border-red-500' : 'border-gray-400'"
                    type="text" 
                    placeholder="XXXX"
                    v-model="inputPin"
                    @input="onInputField"
                  />
                  <p v-if="errorPin" class="text-red-500 text-sm m-2">** รหัสไม่ถูกต้อง กรุณาลองใหม่ **</p>
                </div>

                <div class="text-sm text-slate-500 mt-4 space-x-2">
                  <p v-if="timer < 60" class="space-x-3">
                    <span>{{content?.resendText1}}</span>
                    <span>{{60 - timer}}</span>
                    <span>{{content?.resendUnit}}</span>
                  </p>
                  <p v-if="timer >= 60" class="space-x-2">
                    <span>{{content?.resendText2}}</span> 
                    <a @click="resendPin"  class="font-medium text-indigo-500 hover:text-indigo-600">{{content?.resendText3}}</a>
                  </p>
                </div>

              </div>


              <div class="flex justify-end items-center">
                <button
                  type="button"
                  @click="close"
                  class=" mt-4 py-2.5 px-5 mr-2 text-sm font-medium text-white focus:outline-none bg-red-700 shadow rounded-lg  "
                >
                  <span>Close</span>
                </button>

                <button
                  type="button"
                  @click="confirmPin"
                  class=" mt-4 py-2.5 px-5 mr-2 text-sm font-medium text-white focus:outline-none bg-indigo-500 shadow rounded-lg  "
                >
                  <span>ยืนยัน</span>
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import dayjs from "dayjs";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";
import commaNumber from "comma-number"
const emit = defineEmits(["closed", "update", "resend"]);
const props = defineProps({
  isShow: { type: Boolean, default: false,},
  pin: { type: String, require: true},
  content: { type: Object, default: {
    title: "ต้องการเปลี่ยนแปลงข้อมูล ?",
    text1: "รหัสจะถูกส่งไปยังเบอร์มือถือของเจ้าของงาน",
    text2: "และนำมากรอกรหัสที่นี่เพื่อยืนยันตัวตน",
    error: "รหัสไม่ถูกต้อง กรุณาลองใหม่"
  }}
});

const inputPin = ref('')
const errorPin = ref(false)
const timer = ref(0);
let intervalId = null;


const onInputField = () => {
  errorPin.value = false
}

onMounted(() => {
  resetTimer()
})

onUnmounted(() => {
  clearInterval(intervalId);
})


const confirmPin = () => {
  if(!inputPin.value) {
      errorPin.value = true
      return 
  }

  if(inputPin.value !== props?.pin) {
    errorPin.value = true
    return
  }

  emit("update");
}

const close = () => {
  emit("closed");
};

const resendPin = () => {
  resetTimer()
  emit('resend')
}

const resetTimer = () => {
  clearInterval(intervalId);
  timer.value = 0;
  intervalId = setInterval(function() {
    timer.value++;

    if(timer.value >= 60) {
      clearInterval(intervalId);
      console.log('clear interval and display button resend')
    }
  }, 1000); // Set interval to 60 seconds (60000 ms)
};
</script>
